
    import { mapActions, mapState } from 'vuex';

    export default {
        name: 'LanguageSelector',

        i18nOptions: { namespaces: ['footer'] },

        computed: {
            ...mapState(['app']),
        },

        methods: {
            ...mapActions({
                logOut: 'profile/logOut',
            }),

            getUrlForLocale (locale) {
                let url = this.app.siteURL;

                const vanityDomains = ['starbucksforlife', 'starbucksavie'];

                if (vanityDomains.some((domain) => url.includes(domain))) {
                    return locale.includes('fr') ?
                        'https://www.starbucksavie.ca' :
                        'https://www.starbucksforlife.ca';
                }

                if (url.includes('starbucks')) {
                    const baseDomain = 'starbucks-yearinreview2023';
                    const subDomainRegex = /(?:http[s]*:\/\/)*(.*?)\.(?=[^/]*\..{2,5})/i;
                    const subDomain = url.match(subDomainRegex)[1];
                    const suffix = locale.includes('fr') ? '-frca' : locale.includes('CA') ? '-ca' : '';
                    url = url.replace(subDomain, `${baseDomain}${suffix}`);
                }
                else {
                    url = `${url}?locale=${locale}`;
                }

                return url;
            },

            async handleLocaleClick () {
                try {
                    window._analytics.logout();
                }
                catch (error) {
                    console.error('[GDS] Logout unsuccessful');
                }
                await this.logOut();
            },
        },
    };
