
    import { mapState, mapGetters, mapActions } from 'vuex';
    import { openPopup } from '../popup';
    import LanguageSelector from './LanguageSelector.vue';

    export default {
        name: 'TheFooter',

        i18nOptions: { namespaces: ['footer', 'links', 'global'] },

        components: {
            LanguageSelector,
        },

        computed: {
            ...mapState([
                'app',
                'profile',
            ]),
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
            }),
        },

        methods: {
            ...mapActions({
                logOut: 'profile/logOut',
            }),

            openPopup,

            async signOut () {
                try {
                    window._analytics.logout();
                }
                catch (error) {
                    console.error('[GDS] Logout unsuccessful');
                }

                await this.logOut();
            },
        },
    };

