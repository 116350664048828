
    import { mapGetters } from 'vuex';

    import BasePage from './BasePage';

    const messagesMap = {
        country: 'generic_ineligible',
        email: 'generic_ineligible',
        loyalty: 'generic_ineligible',
        partner: 'ineligible_partner',
    };

    export default {
        name: 'ErrorPage',

        extends: BasePage,

        i18nOptions: { namespaces: ['error'] },

        props: {
            error: {
                type: String,
                default: () => 'generic',
            },
        },

        data () {
            return {
                pageName: 'error',
                pageClasses: ['has-background-1'],
            };
        },

        computed: {
            ...mapGetters({
                loggedIn: 'profile/loggedIn',
            }),

            whichError () {
                /*
                    Available errors
                    /#/error/partner
                    /#/error/country
                    /#/error/loyalty
                    /#/error/email
                */
                return messagesMap[this.error] || 'generic';
            },
        },
    };
