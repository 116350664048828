import { set, reactive } from 'vue';
import axios from 'axios';
import api from '../../api';

const getDefaultState = () => reactive({
    hasCardData: false,
    ccp: 1,
    favorite_time_of_day: 1,
    most_purchased_beverage: 1234,
    most_purchased_beverage_category: 6,
    most_purchased_food: 351,
    most_purchased_food_category: 3,
    past_year_stars_earned: 1364,
    past_year_stars_earned_tier: 4,
    total_transactions: 46,
    total_transactions_tier: 4,
    used_reusable_cup: 1,
    order_channel_preference: 2,
    unique_stores_visited: 13,
    unique_stores_visited_tier: 4,
    past_year_stars_redeemed: 0.0,
    member_since: 2016,
    beverage_temperature_preference: 1,
    top_customizer: 1,
    transaction_frequency: 1,
    unique_cities_visited: 5,
});

const state = getDefaultState();

const getters = {

};

const mutations = {
    loadData (state, newValues) {
        for (const key in newValues) {
            set(state, key, newValues[key]);
        }

        set(state, 'hasCardData', true);
    },

    updateData (state, newValues) {
        for (const key in newValues) {
            set(state, key, newValues[key]);
        }
    },
};

const actions = {
    async getCardData ({ commit, dispatch }) {
        try {
            const response = await axios.get(`${api.base}/user-cards/me`);
            commit('loadData', response.data.userCard);
        }
        catch (error) {
            // If 404, do nothing, because `hasCardData` defaults to false
            if (error.response.status !== 404) {
                dispatch('profile/logOut', null, { root: true });
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
