import store from '../store/index';

// Asynchronous component definitions for lazy-loading routes.
// https://router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    QuizPage = async () => import(
        /* webpackChunkName: 'quiz' */
        '../views/QuizPage.vue'
    ),
    OfferConfirmationPage = async () => import(
        /* webpackChunkName: 'offer' */
        '../views/OfferConfirmationPage.vue'
    ),
    CardBuilderPage = async () => import(
        /* webpackChunkName: 'card-builder' */
        '../views/CardBuilderPage.vue'
    );

export default [
    { path: '/', redirect: (to) => {
        // Used to send server-side errors to the '/error' page.
        if (store.state.app.defaultRoute) {
            return store.state.app.defaultRoute;
        }
        // Default routes for logged-in users.
        else if (store.getters['profile/loggedIn']) {
            // User returning through registration after quiz
            if (!store.getters['app/inPhase']('nonoffer') &&
                store.state.profile.took_quiz &&
                !store.state.profile.has_seen_offer_award_flow) {
                return '/confirmation';
            }

            return '/experience';
        }

        // Default route for a user we know nothing about.
        return '/landing';
    } },
    { path: '/confirmation', component: OfferConfirmationPage },
    { path: '/experience', component: CardBuilderPage  },
    { path: '/guest-experience', component: QuizPage, meta: { public: true } },
];
