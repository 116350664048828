
import { set, reactive } from 'vue';

const personalityConfig = {"_public":true,"surveys":[{"id":"personality-quiz-1","features":["personality"],"publicComponentOptions":{"personalities":{"p1":{"q1":"a1","q3":"a1"},"p2":{"q1":"a1","q2":["a2","a3"],"q3":"a2"},"p3":{"q1":"a3","q3":"a1"},"p4":{"q1":"a2","q2":"a1","q3":"a1"},"p5":{"q1":"a2","q2":"a1","q3":"a2"}}},"questions":[{"id":"q1","type":"select-single","answers":[{"id":"a1","personalities":{"p1":1,"p2":1}},{"id":"a2","personalities":{"p4":1,"p5":1}},{"id":"a3","personalities":{"p3":1}}]},{"id":"q2","type":"select-single","answers":[{"id":"a1","personalities":{"p4":1,"p5":1}},{"id":"a2","personalities":{"p2":1}},{"id":"a3","personalities":{"p2":1}}]},{"id":"q3","type":"select-single","answers":[{"id":"a1","personalities":{"p1":1,"p3":1,"p4":1}},{"id":"a2","personalities":{"p2":1,"p5":1}}]},{"id":"q4","type":"select-single","answers":[{"id":"a1","personalities":{}},{"id":"a2","personalities":{}},{"id":"a3","personalities":{}}]}]}]}.surveys[0].publicComponentOptions.personalities;

const getDefaultState = () => reactive({
    answers: {},
    questionsAnswered: 0,
});

const state = getDefaultState();

const getters = {
    leadPersonality: (state) => {
        for (const personalityId in personalityConfig) {
            const personality = personalityConfig[personalityId];
            let matching = false;

            for (const questionId in personalityConfig[personalityId]) {
                const question = personality[questionId];
                if (Array.isArray(question)) {
                    matching = question.includes(state.answers[questionId]);
                }
                else {
                    matching = state.answers[questionId] === question;
                }

                if (!matching) {
                    break;
                }
            }

            if (matching) {
                return personalityId;
            }
        }

        return 'p0';
    },
};

const mutations = {
    recordAnswer (state, { questionId, answerId }) {
        set(state.answers, questionId, answerId);
        state.questionsAnswered += 1;
    },

    reset (state) {
        const defaultState = getDefaultState();
        for (const key in defaultState) {
            set(state, key, defaultState[key]);
        }
    },
};

const actions = {
    answer ({ commit }, submission) {
        commit('recordAnswer', submission);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
