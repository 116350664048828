var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"general-page"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t(`error:headline_copy.${_vm.whichError}`))+" ")]),_c('p',{directives:[{name:"t",rawName:"v-t",value:({
            key: `body_copy.${_vm.whichError}`,
            tb2Url: _vm.app.tb2URL,
            target: '_blank',
            interpolation: {
                escapeValue: false,
            },
        }),expression:"{\n            key: `body_copy.${whichError}`,\n            tb2Url: app.tb2URL,\n            target: '_blank',\n            interpolation: {\n                escapeValue: false,\n            },\n        }"}]}),(_vm.$t(`cta.${_vm.whichError}`))?[_c('a',{staticClass:"button large",attrs:{"href":_vm.app.siteURL}},[_vm._v(" "+_vm._s(_vm.$t(`error:cta.${_vm.whichError}`))+" ")])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }